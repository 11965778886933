import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Carousel } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Concrete = () => (
  <Layout>
    <SEO
      title="Subdivisions"
      image={"/sub-divisions.jpg"}
      description="We can oversee your subdivision project from beginning to end. From construction through to sign
      off, our multi-civil team can carry out all aspects of your subdivision project."
    />

    <div className="row g-0">
      <div className="col-lg-6 d-flex align-items-center justify-content-center">
        <div className="content-box">
          <h1 className="section-heading section-heading--left">
            Subdivisions
          </h1>
          <p>
            We can oversee your subdivision project from beginning to end. From
            construction through to sign off, our multi-civil team can carry out
            all aspects of your subdivision project.
          </p>
          <p>
            Our team have a proven track record in delivering subdivision
            solutions, from single lot residential subdivisions through to
            multi-unit development projects.
          </p>
          <ul>
            <li>Site preparation</li>
            <li>Excavation</li>
            <li>
              Trenching and Installation of services including:
              <ul>
                <li>Sewer and stormwater systems</li>
                <li>Water mains</li>
                <li>Lateral connections</li>
                <li>Telecommunication infrastructure</li>
                <li>Electrical infrastructure</li>
                <li>Streetlights and cables</li>
                <li>Sumps and leads</li>
                <li>Soakpits</li>
                <li>Pump stations</li>
              </ul>
            </li>
            <li>
              Finish Surfacing:
              <ul>
                <li>Roadways</li>
                <li>Driveways</li>
                <li>Kerb and channel</li>
                <li>Vehicle crossings</li>
                <li>Pavement construction</li>
                <li>Concrete Surfaces</li>
                <li>Asphalt Surfaces</li>
              </ul>
            </li>
            <li>Landscaping, Retaining Walls, Fencing</li>
          </ul>
          <p>
            <Link className="btn btn-primary mt-4" to="/contact/">
              Free quote
            </Link>
          </p>
        </div>
      </div>
      <div className="col-lg-6 d-flex align-items-center justify-content-center">
        {/* <Carousel>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  src="../images/concrete.jpg"
                  alt="concrete path"
                  loading="eager"
                />
              </Carousel.Item>
            </Carousel> */}
        <StaticImage
          // aspectRatio={16 / 12}
          src="../images/sub-divisions.jpg"
          alt="subdivision"
          loading="eager"
        />
      </div>
    </div>
  </Layout>
);

export default Concrete;
